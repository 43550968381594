/* Reset */
@import-normalize;

/* Variables */
:root{
  --typography: #222222;
  --board: #f6e0c3;
  --lines: #bfa17a;
  --background: #fff;
  --token-white: linear-gradient(180deg, #ffffff 0%, #eaeaea 100%);
  --token-black: linear-gradient(180deg, #585858 0%, #333333 100%);
  --token-accent-white: inset 0 -0.5px 6px #00000012;
  --token-accent-black: inset 0 -0.5px 6px #00000040;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --typography: #fff;
    --lines: #f0ecdc;
    --board: #000135;
    --background: #000;
    --token-white: #ff0;
    --token-black: #0ff;
  }
} */

html{
  font-size: 1px;
}

/* Fonts */
@font-face {
  font-family: "Squada One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Squada One"), local("SquadaOne-Regular"),
    url(https://fonts.gstatic.com/l/font?kit=BCasqZ8XsOrx4mcOk6MtWaU8XjBlNAlrQjE&skey=367d3d9b9a52b6b3&v=v8)
      format("woff2");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
}